
.headroom-wrapper header{color: #fff;background-color:#54c8bf}
.MuiDrawer-docked-271{background-color: #ece9e9;}
.MuiPaper-elevation4-37{box-shadow:none;}
.MuiToolbar-dense-64 { min-height: 70px !important;}
.WithTheme-WithWidth-AppBar---menuButton-18{background-color: #ff6364!important;}
.WithTheme-WithWidth-AppBar---menuButton-18 .WithTheme-WithWidth-AppBar---menuButtonIconOpen-20{color:#fff}
.MuiPaper-elevation4-37{box-shadow:none!important;}
.Layout-content-16{padding: 0px;}
.MuiIconButton-root-155.MuiIconButton-colorInherit-156{background: #fff; margin: 5px;}
.MuiIconButton-root-155.MuiIconButton-colorInherit-156 button{color: #fff;}
.filterdoubleinput{    margin-bottom: -20px!important;}
.MuiIconButton-root-155.MuiIconButton-colorInherit-156 .MuiSvgIcon-root-164{font-size: 30px;font-weight: normal;color: #4f4f4f;}